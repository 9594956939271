















/**
 * @description Field for user data like 'name', 'username' or 'last name'
 */
import Vue from "vue";
import rules from "./rules";

export default Vue.extend({
  name: "field-user",
  props: {
    label: String,
    value: String,
    rulesExtra: {
      type: Array,
      default: () => [],
    },
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mValue: "",
      rules: [rules.required, ...this.rulesExtra],
    };
  },
});
