







































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { mapActions } from "vuex";
import rules from "@/components/account/rules";
import { notifyError, notifyInfo } from "@/components/Notification";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "btn-create-ocupation",
  props: {
    btnProps: {
      type: Object,
      default: () => ({}),
    },
    expanded: Boolean,
  },
  data() {
    return {
      dialog: false,
      formValid: false,
      rules,
      loading: false,
      form: {
        name: "",
        type: "Ocupation",
      },
    };
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actCreateSubtypeNomenclator"]),
    create(): void {
      this.loading = true;
      this.actCreateSubtypeNomenclator(this.form)
        .then(() => {
          this.loading = false;
          this.form.name = "";
          (this as any).$refs.form.resetValidation();
          notifyInfo("The ocupation has been created successfully");
          this.resetform();
          (this.dialog = false), this.$emit("created");
        })
        .catch((error: any) => {
          this.loading = false;
          this.dialog = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetform() {
      this.form.name = "";
    },
    close() {
      this.dialog = false;
    },
  },
});
